// @ts-check
import {Backdrop} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import config from "../../../config/config.json";
import {BLOCK_SCREEN_TYPES, EVENTS, FAIL_EVENTS} from "../../../config/event_config";
import BlockedScreen from "../../components/blocked_screen/blocked_screen";
import {selectActiveDisciplineIds} from "../../components/disciplines/disciplines_selectors";
import Legend, {SLOT_TYPE} from "../../components/legend/legend";
import Manage from "../../components/manage/manage";
import {CONFLICT} from "../../components/op_edit_layer/op_edit_layer";
import {clearSaveEditOpStatusAction} from "../../components/op_edit_layer/op_edit_layer_actions";
import {selectSaveEditOpStatus, selectSaveError} from "../../components/op_edit_layer/op_edit_layer_selectors";
import OpManageCanvas from "../../components/op_manage_canvas/op_manage_canvas";
import {clearNamesAction} from "../../components/private_data/private_data_actions";
import RoomsFilterAdvanced from "../../components/rooms/components/rooms_filter_advanced/rooms_filter_advanced";
import {loadRoomsAction} from "../../components/rooms/rooms_actions";
import SearchLayer from "../../components/search_layer/search_layer";
import ActionMenubar from "../../components/shared/action_menubar/action_menubar";
import DatePicker from "../../components/shared/date_picker/date_picker";
import DetailRight from "../../components/shared/detail_right/detail_right";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import ViewSwitch from "../../components/shared/view_switch/view_switch";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import usePrevious from "../../hooks/usePrevious";
import {selectCurrentOrganizationId, selectCurrentTimezone, selectCurrentUserEmail, selectRefreshTrigger} from "../../redux/app_selectors";
import {selectEventCreatedAt, selectEventKey, selectEventState, selectShowBlockscreen} from "../../redux/events/event_selectors";
import {isPending, isRejected, isResolved} from "../../redux/utils/status";
import {loadTimeslotsAction} from "../timeslots/timeslots_actions";
import useStyles from "./op_management.styles";
import {
    changeDate,
    changeSelectedOp,
    fetchAllPatientNamesAction,
    loadAllOpenScheduleAction,
    loadOpManagementScheduleAction,
    loadSearchScheduleAction,
    saveCoreValues,
    saveOpRooms,
    saveVisibleDisciplines,
    setFixedTime,
    simulatorAddEmergencyAction,
    undoManualChangesAction
} from "./op_management_actions";
import {
    selectCompleteStatus,
    selectCoreValues,
    selectDateChanged,
    selectDiscardStatus,
    selectLoadStatus,
    selectManualChanges,
    selectSelectedDate,
    selectSimulator,
    selectStatus,
    selectStatusNewSchedule,
    selectStatusPublishPlan,
    selectUndoStatus,
    selectVisibleDisciplines
} from "./op_management_selectors";
import {getActions} from "./utils/op_manage_action_items";

/**
 * OpManagement component
 * @return {React.ReactElement}
 */
const OpManagement = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const {format, now, plusDT, minusDT} = useContext(DateContext);
    const navigate = useNavigate();

    // States
    const [openSearch, setOpenSearch] = useState(false);
    const [openManage, setOpenManage] = useState(false);
    const [openDetails, setOpenDetails] = useState(false);
    const [openLegend, setOpenLegend] = useState(false);
    const [openRooms, setOpenRooms] = useState(false);

    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [showFullActionMenubar, setShowFullActionMenubar] = useState(false);
    // types of block screen: newPlanAutomatic, newPlanManual minorAdjust, failure. (WIP: newPlanUnsaved, newPlanSaved, newPlanDiscarded)
    const [blockScreenType, setBlockScreenType] = useState(null);
    /** @type [String, Function] */
    const [searchTrigger, setSearchTrigger] = useState();

    // Redux store
    const selectedDate = useSelector(selectSelectedDate);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const status = useSelector(selectStatus);
    const manualChanges = useSelector(selectManualChanges);
    const email = useSelector(selectCurrentUserEmail);
    const statusPublishPlan = useSelector(selectStatusPublishPlan);
    const statusNewSchedule = useSelector(selectStatusNewSchedule);
    const timezone = useSelector(selectCurrentTimezone);
    const loadStatus = useSelector(selectLoadStatus);
    const saveStatus = useSelector(selectSaveEditOpStatus);
    const dateChanged = useSelector(selectDateChanged);
    const coreValues = useSelector(selectCoreValues);
    const discardStatus = useSelector(selectDiscardStatus);
    const completeStatus = useSelector(selectCompleteStatus);
    const simulationStore = useSelector(selectSimulator);
    const visibleDisciplines = useSelector(selectVisibleDisciplines);
    const activeDisciplineIds = useSelector(selectActiveDisciplineIds);
    const refreshTrigger = useSelector(selectRefreshTrigger);
    const undoStatus = useSelector(selectUndoStatus);
    const saveError = useSelector(selectSaveError);

    const showBlockscreen = useSelector(selectShowBlockscreen);
    const eventCreatedAt = useSelector(selectEventCreatedAt);
    const eventKey = useSelector(selectEventKey);
    const eventState = useSelector(selectEventState);

    const prevDiscardStatus = usePrevious(discardStatus);
    const prevCompleteStatus = usePrevious(completeStatus);
    const prevPublishStatus = usePrevious(statusPublishPlan);
    const prevNewStatus = usePrevious(statusNewSchedule);
    const prevSaveStatus = usePrevious(saveStatus);
    const prevLoadStatus = usePrevious(loadStatus);
    const prevSimulationStatus = usePrevious(simulationStore?.status);

    useEffect(() => {
        dispatch(changeDate(now()));
        // clean up function
        return () => {
            dispatch(clearNamesAction());
        };
    }, []);

    useEffect(() => {
        // show error message once
        if (prevLoadStatus && !isRejected(prevLoadStatus) && isRejected(loadStatus)) {
            setError(t("OpManagement.loadError"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [loadStatus]);

    useEffect(() => {
        // reload if search layer is open
        if (isResolved(undoStatus) && openSearch) {
            dispatch(loadOpManagementScheduleAction(organizationId, format(selectedDate, DATE_FORMATS.SYSTEM_DATE)));
            dispatch(loadAllOpenScheduleAction(organizationId));
            setSearchTrigger(new Date().toISOString());
        }
    }, [undoStatus]);

    useEffect(() => {
        if (prevPublishStatus && !isResolved(prevPublishStatus) && isResolved(statusPublishPlan)) {
            setOpenManage(false);
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadOpManagementScheduleAction(organizationId, systemDate));
            dispatch(loadTimeslotsAction(organizationId, systemDate));
            setMessage(t("OpManagement.successPublishPlan"));
            setTimeout(() => setMessage(""), 5 * 1000);
        }
        // show error message once
        if (prevPublishStatus && !isRejected(prevPublishStatus) && isRejected(statusPublishPlan)) {
            setError(t("OpManagement.errorPublishPlan"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [statusPublishPlan]);

    useEffect(() => {
        // Do not show success message for new schedule since block screen is shown until new plan is available

        // show error message once
        if (prevNewStatus && !isRejected(prevNewStatus) && isRejected(statusNewSchedule)) {
            setError(t("OpManagement.errorNewSchedule"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [statusNewSchedule]);

    useEffect(() => {
        if (prevSaveStatus && !isRejected(prevSaveStatus) && isRejected(saveStatus) && saveError !== CONFLICT) {
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadOpManagementScheduleAction(organizationId, systemDate));
            dispatch(loadTimeslotsAction(organizationId, systemDate));
            setError(t("OpManagement.saveError"));
            setTimeout(() => setError(""), 5 * 1000);
            // reset status
            dispatch(clearSaveEditOpStatusAction());
        }
        // reset success status
        if (isResolved(saveStatus)) {
            // reset status
            dispatch(clearSaveEditOpStatusAction());
        }

        // refresh if search layer is open
        if (prevSaveStatus && !isResolved(prevSaveStatus) && isResolved(saveStatus) && dateChanged) {
            setMessage(t("OpManagement.dateChanged", {date: format(selectedDate, DATE_FORMATS.DATE)}));
            setTimeout(() => setMessage(""), 5 * 1000);
        }

        // refresh if search layer is open
        if (prevSaveStatus && !isResolved(prevSaveStatus) && isResolved(saveStatus) && openSearch) {
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadOpManagementScheduleAction(organizationId, systemDate));
            dispatch(loadAllOpenScheduleAction(organizationId));
            dispatch(loadTimeslotsAction(organizationId, systemDate));
            setSearchTrigger(new Date().toISOString());
        }
    }, [saveStatus]);

    useEffect(() => {
        if (prevDiscardStatus && !isResolved(prevDiscardStatus) && isResolved(discardStatus)) {
            setOpenManage(false);
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadOpManagementScheduleAction(organizationId, systemDate));
            dispatch(loadTimeslotsAction(organizationId, systemDate));
            setMessage(t("OpManagement.successDiscard"));
            setTimeout(() => setMessage(""), 5 * 1000);
        }
        // show error message once
        if (prevDiscardStatus && !isRejected(prevDiscardStatus) && isRejected(discardStatus)) {
            setError(t("OpManagement.errorDiscard"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [discardStatus]);

    useEffect(() => {
        if (prevCompleteStatus && !isResolved(prevCompleteStatus) && isResolved(completeStatus)) {
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadOpManagementScheduleAction(organizationId, systemDate));
            dispatch(loadTimeslotsAction(organizationId, systemDate));
            setMessage(t("OpManagement.successComplete"));
            setTimeout(() => setMessage(""), 5 * 1000);
        }
        // show error message once
        if (prevCompleteStatus && !isRejected(prevCompleteStatus) && isRejected(completeStatus)) {
            setError(t("OpManagement.errorComplete"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [completeStatus]);

    useEffect(() => {
        if (prevSimulationStatus && !isResolved(prevSimulationStatus) && isResolved(simulationStore.status)) {
            setMessage(t("OpManagement.successAddEmergencies"));
            setTimeout(() => setMessage(""), 5 * 1000);
        }
        // show error message once
        if (prevSimulationStatus && !isRejected(prevSimulationStatus) && isRejected(simulationStore.status)) {
            setError(t("OpManagement.errorAddEmergencies"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [simulationStore]);

    useEffect(() => {
        if (organizationId && timezone && selectedDate) {
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadOpManagementScheduleAction(organizationId, systemDate));
            dispatch(loadTimeslotsAction(organizationId, systemDate));
            dispatch(loadRoomsAction(organizationId));
        }
    }, [organizationId, timezone, selectedDate]);

    useEffect(() => {
        if (refreshTrigger && selectedDate) {
            const systemDate = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
            dispatch(loadOpManagementScheduleAction(organizationId, systemDate, true));
            dispatch(loadTimeslotsAction(organizationId, systemDate));
        }
    }, [refreshTrigger]);

    useEffect(() => {
        if (!showBlockscreen) {
            setBlockScreenType(null);
        } else {
            setBlockScreenType(BLOCK_SCREEN_TYPES[eventState || eventKey]);
        }
        if (FAIL_EVENTS.includes(eventKey)) {
            setError(t("OpManagement.errorScheduleCalculation"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [eventCreatedAt, showBlockscreen, eventKey, eventState]);

    // The block screen type is usually set from websocket event, but if a user opens the browser after event
    useEffect(() => {
        if (status?.showBlockscreen && BLOCK_SCREEN_TYPES[status?.state]) {
            setBlockScreenType(BLOCK_SCREEN_TYPES[status.state]);
        }
    }, [status]);

    // handlers for layer open
    const handleOpenSearch = () => {
        if (!openSearch) {
            setOpenSearch(true);
            setOpenManage(false);
            setOpenRooms(false);
            setOpenLegend(false);
            fetchAllSchedules();
        } else {
            setOpenSearch(false);
        }
    };
    /**
     * fetch all schedules - searchOps, openOps and allSearchOps (patient names)
     */
    const fetchAllSchedules = () => {
        // Fetch for the result
        const fromDate = format(now(), DATE_FORMATS.SYSTEM_DATE);
        const toDate = format(plusDT(now(), "day", 28), DATE_FORMATS.SYSTEM_DATE);
        dispatch(loadSearchScheduleAction({organizationId, email, fromDate, toDate}));

        // Fetch for the patient names
        // @ts-ignore @Adrian any idea how to fix?
        const minFromDate = format(minusDT(now(), config.MIN_SEARCH_DATE.unit, config.MIN_SEARCH_DATE.amount), DATE_FORMATS.SYSTEM_DATE);
        // @ts-ignore @Adrian any idea how to fix?
        const maxToDate = format(plusDT(now(), config.MAX_SEARCH_DATE.unit, config.MAX_SEARCH_DATE.amount), DATE_FORMATS.SYSTEM_DATE);

        dispatch(fetchAllPatientNamesAction({organizationId, email, minFromDate, maxToDate, onlyPatientIds: true}));
        dispatch(loadAllOpenScheduleAction(organizationId));
    };
    const handleOpenManage = () => {
        if (!openManage) {
            setOpenSearch(false);
            setOpenManage(true);
            setOpenRooms(false);
            setOpenLegend(false);
        } else {
            setOpenManage(false);
        }
    };
    const handleOpenDetails = (opId) => {
        setOpenDetails(true);
        dispatch(changeSelectedOp(opId));
    };
    const handleOpenLegend = () => {
        if (!openLegend) {
            setOpenSearch(false);
            setOpenManage(false);
            setOpenRooms(false);
            setOpenLegend(true);
        } else {
            setOpenLegend(false);
        }
    };
    const handleOpenRooms = () => {
        if (!openRooms) {
            setOpenSearch(false);
            setOpenManage(false);
            setOpenRooms(true);
            setOpenLegend(false);
        } else {
            setOpenRooms(false);
        }
    };

    // handlers for layer close
    const handleCloseSearch = () => {
        setOpenSearch(false);
    };
    const handleCloseManage = () => {
        setOpenManage(false);
    };

    const handleCloseLegend = () => {
        setOpenLegend(false);
    };
    const handleCloseRooms = () => {
        setOpenRooms(false);
    };

    const handleUndo = () => {
        const lastChange = manualChanges[manualChanges.length - 1];

        dispatch(
            undoManualChangesAction({
                sessionId: status.session._id,
                id: lastChange.id,
                userEmail: email
            })
        );
    };

    const handleChangeDate = (date) => {
        dispatch(loadOpManagementScheduleAction(organizationId, format(date, DATE_FORMATS.SYSTEM_DATE)));
        dispatch(loadTimeslotsAction(organizationId, format(date, DATE_FORMATS.SYSTEM_DATE)));
        dispatch(changeDate(date));
        dispatch(setFixedTime(false));
    };
    const renderHeaderOptions = () => (
        <DatePicker changeDate={handleChangeDate} loading={isPending(loadStatus)} selectedDate={selectedDate} />
    );

    const handleToggleActionMenubarWidth = () => {
        setShowFullActionMenubar(!showFullActionMenubar);
    };

    // Set actions for right action menubar
    let actions = [];
    if (status && status.transitions) {
        const handlers = {
            handleUndo,
            handleOpenSearch,
            handleOpenManage,
            handleOpenLegend,
            handleOpenRooms
        };
        const isOpened = {
            openSearch,
            openManage,
            openLegend,
            openRooms
        };
        const disabled = {
            undo: !(status.session.editedBy === email && status.transitions.includes(EVENTS.EditManualPlan)) || isPending(undoStatus)
        };
        actions = getActions(handlers, isOpened, disabled);
    }

    const handleAddEmergency = () => {
        dispatch(simulatorAddEmergencyAction());
    };

    // show add emergency button
    const urlParams = new URLSearchParams(window.location.search);
    const showAddEmergencyButton = parseInt(global.ALLOWED_ADD_EMERGENCY, 10) && urlParams.get("simulator");
    if (showAddEmergencyButton === "enabled") {
        actions.push({
            iconName: "LocalHospital",
            iconColor: "error",
            disabled: isPending(simulationStore.status),
            handler: handleAddEmergency,
            translationKey: "OpManagementActions.addEmergency",
            isOpened: false
        });
    }

    const handleSaveRoomsFilter = ({planSetting, roomsFilter, disciplinesFilter, opRooms}) => {
        const coreValuesNew = {...coreValues};
        coreValuesNew.planSetting = planSetting;
        coreValuesNew.roomsFilter = [...roomsFilter];
        coreValuesNew.disciplinesFilter = [...disciplinesFilter];
        coreValuesNew.rowCount = opRooms.length;

        dispatch(saveCoreValues(coreValuesNew));
        dispatch(saveOpRooms(opRooms));
    };

    /**
     * toggle visibleDisciplines
     * @param {string} id   healthcareServiceIds + "EMERGENCY" + "ALL"
     */
    const handleToggleVisibleDisciplines = (id) => {
        if (visibleDisciplines.includes("ALL")) {
            if (id === "ALL") {
                dispatch(saveVisibleDisciplines([]));
            } else {
                // Check all ids except given id
                const activeDisciplineIdsWithEmergency = [...activeDisciplineIds, SLOT_TYPE.EMERGENCY];
                dispatch(saveVisibleDisciplines([...activeDisciplineIdsWithEmergency.filter((activeId) => activeId !== id)]));
            }
        } else if (visibleDisciplines.includes(id)) {
            // id is already selected
            if (id === "ALL") {
                dispatch(saveVisibleDisciplines([]));
            } else {
                // Remove id from the list
                dispatch(saveVisibleDisciplines([...visibleDisciplines.filter((selected) => selected !== id)]));
            }
        } else {
            if (id === "ALL") {
                dispatch(saveVisibleDisciplines([id]));
            } else {
                // Add id to the list
                const updatedVisibleDisciplines = [...visibleDisciplines];
                updatedVisibleDisciplines.push(id);
                dispatch(saveVisibleDisciplines(updatedVisibleDisciplines));
            }
        }
    };

    const headerItems = [
        <ViewSwitch
            isChecked={false}
            key="view-switch"
            labels={{left: t("OpManagementPage.backlog"), right: ""}}
            title={t("OpManagementPage.backlog")}
            onToggle={() => {
                navigate("/op-management/schedule-management/backlog");
            }}
        />
    ];
    const disableTopLayerClose = openDetails;
    if (!organizationId || !selectedDate) return null;
    return (
        <DndProvider backend={HTML5Backend}>
            {message && <Message message={message} />}
            {error && <Message message={error} severity="error" />}
            <Page
                editedBy={status.session && status.session.editedBy}
                fullActionMenubar={showFullActionMenubar}
                fullCanvas
                headerItems={headerItems}
                name="opManagementPage"
                openRightLayer={openManage || openSearch || openLegend}
                organizationId={organizationId}
                renderOptions={renderHeaderOptions}
                title={t("OpManagementPage.title")}
            >
                <OpManageCanvas
                    isBlockscreenVisible={Boolean(blockScreenType)}
                    openRightLayer={openManage || openSearch}
                    showFullActionMenubar={showFullActionMenubar}
                    onOpenDetails={handleOpenDetails}
                />
                {
                    <ActionMenubar
                        actions={actions}
                        showFullActionMenubar={showFullActionMenubar}
                        onToggleWidth={handleToggleActionMenubarWidth}
                    />
                }
                {openSearch && (
                    <DetailRight
                        disableEsc={disableTopLayerClose}
                        fullActionMenubar={showFullActionMenubar}
                        open={openSearch}
                        onClose={handleCloseSearch}
                    >
                        <SearchLayer
                            isBlockscreenVisible={Boolean(blockScreenType)}
                            searchTrigger={searchTrigger}
                            onOpenDetails={handleOpenDetails}
                        />
                    </DetailRight>
                )}
                {openManage && (
                    <DetailRight
                        disableEsc={disableTopLayerClose}
                        fullActionMenubar={showFullActionMenubar}
                        open={openManage}
                        onClose={handleCloseManage}
                    >
                        <Manage />
                    </DetailRight>
                )}
                {openLegend && (
                    <DetailRight fullActionMenubar={showFullActionMenubar} open={openLegend} onClose={handleCloseLegend}>
                        <Legend hasCheckbox={true} visibleDisciplines={visibleDisciplines} onToggle={handleToggleVisibleDisciplines} />
                    </DetailRight>
                )}
                {openRooms && (
                    <DetailRight fullActionMenubar={showFullActionMenubar} open={openRooms} onClose={handleCloseRooms}>
                        <RoomsFilterAdvanced
                            disciplineRoomsMapping={coreValues.disciplineRoomsMapping}
                            disciplinesFilter={coreValues.disciplinesFilter}
                            isPlanSettingRequired
                            occupiedOpRooms={coreValues.occupiedOpRooms}
                            planSetting={coreValues.planSetting}
                            roomsFilter={coreValues.roomsFilter}
                            onSave={handleSaveRoomsFilter}
                        />
                    </DetailRight>
                )}
                <Backdrop className={classes.backdrop} open={Boolean(blockScreenType)}>
                    <BlockedScreen type={blockScreenType} />
                </Backdrop>
            </Page>
        </DndProvider>
    );
};

OpManagement.propTypes = {};
export default OpManagement;
