const ActionTypes = {
    CHANGE_DATE: "op_management/CHANGE_DATE",
    SAVE_POSITION: "op_management/SAVE_POSITION",
    SAVE_OP_ROOMS: "op_management/SAVE_OP_ROOMS",
    SAVE_CORE_VALUES: "op_management/SAVE_CORE_VALUES",
    SAVE_DRAG_START_POSITION: "op_management/SAVE_DRAG_START_POSITION",

    LOAD_REQUEST: "op_management/LOAD_SCHEDULE_REQUEST",
    LOAD_SUCCESS: "op_management/LOAD_SCHEDULE_SUCCESS",
    LOAD_FAILURE: "op_management/LOAD_SCHEDULE_FAILURE",

    DETAIL_REQUEST: "op_management/DETAIL_OP_REQUEST",
    DETAIL_SUCCESS: "op_management/DETAIL_OP_SUCCESS",
    DETAIL_FAILURE: "op_management/DETAIL_OP_FAILURE",

    SAVE_MANUAL_CHANGE_REQUEST: "op_management/SAVE_MANUAL_CHANGE_REQUEST",
    SAVE_MANUAL_CHANGE_SUCCESS: "op_management/SAVE_MANUAL_CHANGE_SUCCESS",
    SAVE_MANUAL_CHANGE_FAILURE: "op_management/SAVE_MANUAL_CHANGE_FAILURE",

    PUBLISH_REQUEST: "op_management/PUBLISH_REQUEST",
    PUBLISH_SUCCESS: "op_management/PUBLISH_SUCCESS",
    PUBLISH_FAILURE: "op_management/PUBLISH_FAILURE",

    NEW_SCHEDULE_REQUEST: "op_management/NEW_SCHEDULE_REQUEST",
    NEW_SCHEDULE_SUCCESS: "op_management/NEW_SCHEDULE_SUCCESS",
    NEW_SCHEDULE_FAILURE: "op_management/NEW_SCHEDULE_FAILURE",

    SEARCH_REQUEST: "op_management/SEARCH_REQUEST",
    SEARCH_SUCCESS: "op_management/SEARCH_SUCCESS",
    SEARCH_FAILURE: "op_management/SEARCH_FAILURE",

    FETCH_ALL_PATIENT_NAMES_REQUEST: "op_management/FETCH_ALL_PATIENT_NAMES_REQUEST",
    FETCH_ALL_PATIENT_NAMES_SUCCESS: "op_management/FETCH_ALL_PATIENT_NAMES_SUCCESS",
    FETCH_ALL_PATIENT_NAMES_FAILURE: "op_management/FETCH_ALL_PATIENT_NAMES_FAILURE",

    COMPLETE_REQUEST: "op_management/COMPLETE_REQUEST",
    COMPLETE_SUCCESS: "op_management/COMPLETE_SUCCESS",
    COMPLETE_FAILURE: "op_management/COMPLETE_FAILURE",

    DISCARD_REQUEST: "op_management/DISCARD_REQUEST",
    DISCARD_SUCCESS: "op_management/DISCARD_SUCCESS",
    DISCARD_FAILURE: "op_management/DISCARD_FAILURE",

    UNDO_REQUEST: "op_management/UNDO_REQUEST",
    UNDO_SUCCESS: "op_management/UNDO_SUCCESS",
    UNDO_FAILURE: "op_management/UNDO_FAILURE",

    GET_CRITICAL_CHANGES: "op_management/GET_CRITICAL_CHANGES",
    CLEAR_CRITICAL_CHANGES: "op_management/CLEAR_CRITICAL_CHANGES",

    CHANGE_HOVEREDOP: "op_management/CHANGE_HOVEREDOP",
    CHANGE_SELECTEDOP: "op_management/CHANGE_SELECTEDOP",
    CHANGE_HIGHLIGHTEDOP: "op_management/CHANGE_HIGHLIGHTEDOP",

    GET_USERNAMES: "op_management/GET_USERNAMES",

    SET_FIXED_TIME: "op_management/SET_FIXED_TIME",

    LOAD_ALL_OPEN_SCHEDULE_REQUEST: "op_management/LOAD_ALL_OPEN_SCHEDULE_REQUEST",
    LOAD_ALL_OPEN_SCHEDULE_SUCCESS: "op_management/LOAD_ALL_OPEN_SCHEDULE_SUCCESS",
    LOAD_ALL_OPEN_SCHEDULE_FAILURE: "op_management/LOAD_ALL_OPEN_SCHEDULE_FAILURE",

    SET_SCROLL_TO: "op_management/SET_SCROLL_TO",

    SAVE_STATUS: "op_management/SAVE_STATUS",

    LOAD_STATE_REQUEST: "op_management/LOAD_STATE_REQUEST",
    LOAD_STATE_SUCCESS: "op_management/LOAD_STATE_SUCCESS",
    LOAD_STATE_FAILURE: "op_management/LOAD_STATE_FAILURE",

    TRIGGER_EMERGENCY_REQUEST: "op_management/TRIGGER_EMERGENCY_REQUEST",
    TRIGGER_EMERGENCY_SUCCESS: "op_management/TRIGGER_EMERGENCY_SUCCESS",
    TRIGGER_EMERGENCY_FAILURE: "op_management/TRIGGER_EMERGENCY_FAILURE",

    SAVE_VISIBLE_DISCIPLINES: "op_management/SAVE_VISIBLE_DISCIPLINES",

    SET_OVERLAP_HOVERED_OP: "op_management/SET_OVERLAP_HOVERED_OP",
    SET_OVERLAP_CLICKED_OP: "op_management/SET_OVERLAP_CLICKED_OP"
};

export default ActionTypes;
