// @ts-check

import {RESOURCE_HISTORY} from "../../../config/api_config";
import {EVENTS} from "../../../config/event_config";
import {APPOINTMENT_STATUS, SERVICEREQUEST_STATUS_SHORT} from "../../../config/op_status";
import {loadNamesAction} from "../../components/private_data/private_data_actions";
import getPersonIdsFromScheduleOps from "../../components/private_data/utils/get_person_ids_from_schedule_ops";
import {setRefreshTrigger} from "../../redux/actions";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import STATUS from "../../redux/utils/status";
import logger from "../../utils/logger_pino";
import {sliceIntoChunks} from "../../utils/slice_into_chunks";
import verifyOpBacklogOps from "../../utils/verify_op_backlog_ops";
import {selectStatus} from "../op_management/op_management_selectors";
import {ActionTypes} from "./op_backlog_action_types";
import {completeAndPublishAPI, fetchOpBacklogList, fetchResourceHistory, publishPlanAPI} from "./op_backlog_api";

/** @typedef {import("redux").Dispatch} Dispatch */
/** @typedef {import("redux").Action} Action */
/** @typedef {import("./op_backlog_action_types").ActionTypes} OpBacklogActionTypes  */

/**
 * An action creator to start the request to load the op-backlog data
 *
 * @return {{type: OpBacklogActionTypes}}
 */
const loadOpBacklogRequestAction = () => ({
    type: ActionTypes.LOAD_OP_BACKLOG_REQUEST
});

/**
 * An action creator for the success
 *
 * @param {array} payload
 * @param {Status} status
 * @return {{type: OpBacklogActionTypes, payload: array, status: Status}}
 */
const loadOpBacklogSuccessAction = (payload, status) => ({
    type: ActionTypes.LOAD_OP_BACKLOG_SUCCESS,
    payload,
    status
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {{type: OpBacklogActionTypes, error: string}}
 */
const loadOpBacklogFailureAction = (error) => ({
    type: ActionTypes.LOAD_OP_BACKLOG_FAILURE,
    error
});

/**
 * Load the surgery assignment from the data base
 *
 * @param {Object} param
 * @param {string} param.date - system date in format "yyyy-MM-dd"
 * @return {any}
 */
export const loadOpBacklogList =
    ({date}) =>
    (/** @type {Dispatch}} */ dispatch, getState) => {
        dispatch(loadOpBacklogRequestAction());
        const organizationId = selectCurrentOrganizationId(getState());
        const email = selectCurrentUserEmail(getState());

        fetchOpBacklogList(organizationId, email, date)
            .then(([{data: ki}, {data: real}]) => {
                const allData = (ki?.data || []).concat(real?.data || []);

                const status = real.ok && ki.ok ? STATUS.RESOLVED : STATUS.REJECTED;
                // success action
                dispatch(loadOpBacklogSuccessAction(allData, status));

                // Check if there are any ops that have unexpected status
                const {ok, errOps} = verifyOpBacklogOps(allData);
                if (!ok) {
                    logger.warn("Unexpected op status", {errOps, organizationId, email});
                }

                // load names
                const {practitionerIds} = getPersonIdsFromScheduleOps(allData);
                if (practitionerIds.length) {
                    dispatch(loadNamesAction("practitioner", practitionerIds, false));
                }

                const {STATUS_ON_HOLD} = RESOURCE_HISTORY;

                // load changes of status to on-hold
                const onHoldServiceRequestStatus = [SERVICEREQUEST_STATUS_SHORT.ACTIVE, SERVICEREQUEST_STATUS_SHORT.ON_HOLD];
                const statusCancelledIds = allData
                    .filter(
                        (op) => op._status === APPOINTMENT_STATUS.CANCELLED && onHoldServiceRequestStatus.includes(op._statusServiceRequest)
                    )
                    .map((op) => op.id);

                dispatch(
                    loadResourceHistoryAction(STATUS_ON_HOLD.resource, STATUS_ON_HOLD.path, statusCancelledIds, STATUS_ON_HOLD.changedValue)
                );
            })
            .catch((error) => {
                dispatch(loadOpBacklogFailureAction(error));
            });
    };

/**
 * An action creator to toggle expand/fold column
 *
 * @param {String} columnId surgeons or anesthesia
 * @return {{type: OpBacklogActionTypes, columnId: String}}
 */
export const toggleExpandColumnAction = (columnId) => ({
    type: ActionTypes.TOGGLE_COLUMN_EXPAND,
    columnId
});

/**
 * An action creator to toggle expand/fold filters
 *
 * @return {{type: OpBacklogActionTypes}}
 */
export const toggleExpandFilterAction = () => ({type: ActionTypes.TOGGLE_FILTER_EXPAND});

/**
 * An action creator for the request
 *
 * @return {{type: OpBacklogActionTypes}}
 */
const loadResourceHistoryRequestAction = () => ({
    type: ActionTypes.LOAD_RESOURCE_HISTORY_REQUEST
});

/**
 * An action creator for the success
 *
 * @param {array} payload
 * @param {String} resource The resource to be fetched. ex. "Appointment", "manualChanges"
 * @param {String} path The path to be fetched. ex. "status/cancelled", "reasonForChange"
 * @return {{type: OpBacklogActionTypes, payload: array, resource: String, path: String}}
 */
const loadResourceHistorySuccessAction = (payload, resource, path) => ({
    type: ActionTypes.LOAD_RESOURCE_HISTORY_SUCCESS,
    payload,
    resource,
    path
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {{type: OpBacklogActionTypes, error: string}}
 */
const loadResourceHistoryFailureAction = (error) => ({
    type: ActionTypes.LOAD_RESOURCE_HISTORY_FAILURE,
    error
});

/**
 * An action creator to fetch counter from the resource history service
 * @param {String} resource The resource to be fetched. ex. "Appointment", "manualChanges"
 * @param {String} path The path to be fetched. ex. "status/cancelled", "reasonForChange"
 * @param {Array<String>} ids
 * @param {string} [changedValue]
 * @return {any}
 */
export const loadResourceHistoryAction = (resource, path, ids, changedValue) => (/** @type {Dispatch}} */ dispatch) => {
    dispatch(loadResourceHistoryRequestAction());
    // Split into chunkIds
    const chunkIds = sliceIntoChunks(ids, RESOURCE_HISTORY.MAX_NUMBER_OF_IDS);
    fetchResourceHistory(resource, path, chunkIds, changedValue)
        .then((allResult) => {
            // success action
            const result = [];
            allResult.forEach(({data}, index) => {
                const chunkResult = chunkIds[index].map((id) => ({
                    id,
                    changes: data.changes.filter((change) => change.resourceUri === `${resource}/${id}`) || []
                }));
                result.push(...chunkResult);
            });
            dispatch(loadResourceHistorySuccessAction(result || [], resource, path));
        })
        .catch((error) => {
            dispatch(loadResourceHistoryFailureAction(error));
        });
};

/**
 * An action creator to start publishing the schedule
 *
 * @return {{type: OpBacklogActionTypes}}
 */
const publishRequestAction = () => ({
    type: ActionTypes.PUBLISH_REQUEST
});

/**
 * An action creator for the success of publishing the schedule
 *
 * @return {{type: OpBacklogActionTypes}}
 */
const publishSuccessAction = () => ({
    type: ActionTypes.PUBLISH_SUCCESS
});

/**
 * An action creator for the error of publishing the schedule
 *
 * @param {string} error
 * @return {{type: OpBacklogActionTypes, error: string}}
 */
const publishFailureAction = (error) => ({
    type: ActionTypes.PUBLISH_FAILURE,
    error
});

/**
 * @callback PublishAction
 * @param {Dispatch} dispatch
 * @param {Function} getState
 */

/**
 * publish the schedule in op backlog
 *
 * @return {Function}
 */
export const publishAction = () => /** @type PublishAction */ (dispatch, getState) => {
    dispatch(publishRequestAction());
    const userEmail = selectCurrentUserEmail(getState());
    const state = selectStatus(getState());

    const payload = {
        sessionId: state?.session?._id,
        userEmail
    };

    // If there are open manual changes and the transitions include CompleteManualChanges, call the completeAndPublish route.
    if (state.transitions?.includes(EVENTS.CompleteManualChanges)) {
        completeAndPublishAPI(payload)
            .then(() => {
                // refetch
                dispatch(setRefreshTrigger()); // in order to refetch op backlog
                dispatch(publishSuccessAction());
            })
            .catch((error) => {
                dispatch(publishFailureAction(error));
            });
    } else {
        publishPlanAPI(payload)
            .then(() => {
                // refetch
                dispatch(setRefreshTrigger()); // in order to refetch op backlog
                dispatch(publishSuccessAction());
            })
            .catch((error) => {
                dispatch(publishFailureAction(error));
            });
    }
};

/**
 * An action creator for resetting the publish status
 *
 * @return {{type: OpBacklogActionTypes}}
 */
export const resetPublishStatusAction = () => ({
    type: ActionTypes.RESET_PUBLISH_STATUS
});
