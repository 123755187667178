import uniq from "lodash/uniq";

import {selectFeSettings} from "../../components/fe_settings/fe_settings_selectors";
import {loadNamesAction} from "../../components/private_data/private_data_actions";
import getIdsFromCriticalDifferences from "../../components/private_data/utils/get_ids_from_critical_differences";
import getIdsFromPopup from "../../components/private_data/utils/get_ids_from_popup";
import getPersonIdsFromManualChanges from "../../components/private_data/utils/get_person_ids_from_manual_changes";
import getPersonIdsFromScheduleOps from "../../components/private_data/utils/get_person_ids_from_schedule_ops";
import {luxonToken} from "../../contexts/dates";
import {authUserFailureAction} from "../../redux/actions/index";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import STATUS from "../../redux/utils/status";
import logger from "../../utils/logger_pino";
import {format} from "../../utils/luxon_helpers";
import verifyAppointments from "../../utils/verify_appointments";
import ActionTypes from "./op_management_action_types";
import {
    completeManualChangesAPI,
    discardManualChangesAPI,
    fetchAllOpenSchedule,
    fetchAllOpManagementSchedule,
    fetchOpDetails,
    fetchOpenScheduleOnSelectedDate,
    fetchSearchSchedule,
    fetchStatus,
    getBacklogs,
    getUsernamesAPI,
    publishPlan,
    requestSchedule,
    saveManualChangeAPI,
    simulatorInsertEmergenciesAPI,
    undoManualChangesAPI
} from "./op_management_api";
import {selectManualChanges, selectSavedManualChanges, selectSelectedDate, selectStatus} from "./op_management_selectors";

/**
 * Selected date for the main view
 * @param {object} payload DateTime luxon object
 * @return {{type: string, payload: *}}
 */
function changeDate(payload) {
    return {type: ActionTypes.CHANGE_DATE, payload};
}

const loadOpManagementScheduleRequestAction = (keepStatus) => ({
    type: ActionTypes.LOAD_REQUEST,
    keepStatus
});

const loadOpManagementScheduleSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_SUCCESS,
    payload
});

const loadOpManagementScheduleFailureAction = (error) => ({
    type: ActionTypes.LOAD_FAILURE,
    error
});

/**
 * load ki plans
 * @param {string} organizationId
 * @param {string} selectedDate in ISO8061 format ex. 2020-04-23
 * @param {Boolean} [keepStatus = false] if true, don't set status pending on request
 * @return {AnyAction}
 */
function loadOpManagementScheduleAction(organizationId, selectedDate, keepStatus = false) {
    return function (dispatch, getState) {
        dispatch(loadOpManagementScheduleRequestAction(keepStatus));
        const email = selectCurrentUserEmail(getState());

        fetchAllOpManagementSchedule(organizationId, selectedDate, email)
            .then(([{data: kiNew}, {data: published}, {data: state}, {data: real}]) => {
                // verify appointments
                const {ok: okNewOps, okOps: newOps, errOps: errorNewOps} = verifyAppointments(kiNew.data);
                const {ok: okPublishedOps, okOps: publishedOps, errOps: errorPublishedOps} = verifyAppointments(published.data);
                const {ok: okRealOps, okOps: realOps, errOps: errorRealOps} = verifyAppointments(real.data);

                // send warnings if the data is incomplete
                if (!okNewOps || !okPublishedOps || !okRealOps) {
                    logger.warn("OpManage: error appointments", {
                        organizationId,
                        email,
                        errorNewOps,
                        errorPublishedOps,
                        errorRealOps
                    });
                }
                // success action
                const status = kiNew.ok && published.ok && real.ok && state.ok ? STATUS.RESOLVED : STATUS.REJECTED;
                dispatch(
                    loadOpManagementScheduleSuccessAction({
                        open: newOps,
                        accepted: publishedOps.concat(realOps),
                        status: state.ok ? state?.data?.state : {},
                        loadStatus: status
                    })
                );

                // load critical changes
                const solvedEventsIds = state?.data?.state?.session.solvedEvents?.map((item) => item.reference);
                if (solvedEventsIds && solvedEventsIds.length) {
                    dispatch(getCriticalChangesAction(solvedEventsIds));
                } else {
                    dispatch(clearCriticalChangesAction());
                }

                // load names
                const {practitionerIds, patientIds} = getPersonIdsFromScheduleOps(publishedOps.concat(realOps).concat(newOps));
                const {practitionerIds: mcPracIds, patientIds: mcPatientIds} = getPersonIdsFromManualChanges(state?.data?.state);
                const cdPracIds = getIdsFromCriticalDifferences(state?.data?.state?.lastKiSchedule.criticalDifferences, "surgeon1");
                const cdPatientIds = getIdsFromCriticalDifferences(state?.data?.state?.lastKiSchedule.criticalDifferences, "personId");

                if (practitionerIds?.length || mcPracIds?.length || cdPracIds?.length) {
                    dispatch(loadNamesAction("practitioner", [...new Set(practitionerIds.concat(mcPracIds).concat(cdPracIds))], false));
                }
                if (patientIds?.length || mcPatientIds?.length || cdPatientIds?.length) {
                    dispatch(loadNamesAction("patient", [...new Set(patientIds.concat(mcPatientIds).concat(cdPatientIds))], false));
                }
            })
            .catch((error) => {
                // if status is 401 (unauthorized), i.e user is not active
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch schedule error"}));
                } else {
                    dispatch(loadOpManagementScheduleFailureAction(error));
                }
            });
    };
}
const loadSearchScheduleRequestAction = () => ({
    type: ActionTypes.SEARCH_REQUEST
});

const loadSearchScheduleSuccessAction = (payload) => ({
    type: ActionTypes.SEARCH_SUCCESS,
    payload
});

const loadSearchScheduleFailureAction = (error) => ({
    type: ActionTypes.SEARCH_SUCCESS,
    error
});
/**
 * load appointments for search
 * @param {Object} params
 * @return {AnyAction}
 */
function loadSearchScheduleAction(params) {
    return function (dispatch, getState) {
        dispatch(loadSearchScheduleRequestAction());

        fetchSearchSchedule(params)
            .then(({data}) => {
                // verify appointments
                const {ok, okOps, errOps} = verifyAppointments(data.data);

                // send warnings
                if (!ok) {
                    const email = selectCurrentUserEmail(getState());
                    const organizationId = selectCurrentOrganizationId(getState());

                    logger.warn("SearcOps: error appointments", {
                        organizationId,
                        email,
                        errOps
                    });
                }

                dispatch(loadSearchScheduleSuccessAction(okOps));

                // load names, but not replace existing names
                const {practitionerIds, patientIds} = getPersonIdsFromScheduleOps(okOps);
                if (practitionerIds && practitionerIds.length) {
                    dispatch(loadNamesAction("practitioner", practitionerIds, false));
                }
                if (patientIds && patientIds.length) {
                    dispatch(loadNamesAction("patient", patientIds, false));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch search error"}));
                } else {
                    dispatch(loadSearchScheduleFailureAction(error));
                }
            });
    };
}

const fetchAllPatientNamesRequestAction = () => ({
    type: ActionTypes.FETCH_ALL_PATIENT_NAMES_REQUEST
});

const fetchAllPatientNamesSuccessAction = () => ({
    type: ActionTypes.FETCH_ALL_PATIENT_NAMES_SUCCESS
});

const fetchAllPatientNamesFailureAction = (error) => ({
    type: ActionTypes.FETCH_ALL_PATIENT_NAMES_FAILURE,
    error
});
/**
 * load appointments for search
 * @param {Object} params
 * @return {any}
 */
function fetchAllPatientNamesAction(params) {
    return function (dispatch) {
        dispatch(fetchAllPatientNamesRequestAction());

        fetchSearchSchedule(params)
            .then(({data}) => {
                dispatch(fetchAllPatientNamesSuccessAction(data.data));

                // load names, but not replace existing names
                const {patientIds} = getPersonIdsFromScheduleOps(data.data);

                if (patientIds && patientIds.length) {
                    dispatch(loadNamesAction("patient", patientIds, false, params.onlyPatientIds));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch search error"}));
                } else {
                    dispatch(fetchAllPatientNamesFailureAction(error));
                }
            });
    };
}

const loadDetailOpRequestAction = () => ({
    type: ActionTypes.DETAIL_REQUEST
});

const loadDetailOpSuccessAction = (payload) => ({
    type: ActionTypes.DETAIL_SUCCESS,
    payload
});

const loadDetailOpFailureAction = (error) => ({
    type: ActionTypes.DETAIL_FAILURE,
    error
});

/**
 * load op details
 * @param {string} organizationId
 * @param {string} opId
 * @param {boolean} [usePublishedPlan]
 * @return {AnyAction}
 */
function loadDetailOpAction(organizationId, opId, usePublishedPlan = false) {
    return function (dispatch, getState) {
        dispatch(loadDetailOpRequestAction());

        fetchOpDetails(organizationId, opId, usePublishedPlan)
            .then(({data}) => {
                dispatch(loadDetailOpSuccessAction(data.data));

                const {participantCategories} = selectFeSettings(getState());
                // load names, but not replace existing names
                const ids = getIdsFromPopup(data.data, participantCategories);

                if (ids?.length) {
                    dispatch(loadNamesAction("practitioner", ids, false));
                }
                // patient should not be loaded since it must have been loaded beforehand in load actions
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch details error"}));
                } else {
                    dispatch(loadDetailOpFailureAction(error));
                }
            });
    };
}

/**
 * save position of canvas
 * @param {object} payload object of scrollHeight, scrollTop, clientHight
 * @return {{type: string, payload: *}}
 */
function savePosition(payload) {
    return {type: ActionTypes.SAVE_POSITION, payload};
}
/**
 * save y-axis labels
 * @param {object} payload array of y-axis labels
 * @return {{type: string, payload: *}}
 */
function saveOpRooms(payload) {
    return {type: ActionTypes.SAVE_OP_ROOMS, payload};
}

/**
 * load op details
 * @param {Object} payload
 * @param {String} dateChanged
 * @return {function}
 */
function saveManualChangeBackend(payload, dateChanged) {
    return function (dispatch, getState) {
        dispatch(saveManualChangeRequest(payload.manualChanges[0]));

        saveManualChangeAPI(payload)
            .then(() => {
                const organizationId = selectCurrentOrganizationId(getState());
                const selectedDate = selectSelectedDate(getState());
                fetchOpenScheduleOnSelectedDate(organizationId, format(selectedDate, luxonToken.SYSTEM_DATE.de))
                    .then(({data}) => {
                        dispatch(saveManualChangeSuccess(data.data, dateChanged));
                    })
                    .catch((error) => {
                        dispatch(saveManualChangeFailure(error));
                    });
                fetchStatus(organizationId)
                    .then(({data}) => {
                        dispatch(saveStatusAction(data?.data?.state));
                    })
                    .catch((error) => {
                        dispatch(saveManualChangeFailure(error));
                    });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "save manual change error"}));
                } else {
                    dispatch(saveManualChangeFailure(error));
                }
            });
    };
}
const saveManualChangeRequest = (payload) => ({
    type: ActionTypes.SAVE_MANUAL_CHANGE_REQUEST,
    payload
});

const saveManualChangeSuccess = (payload, dateChanged) => ({
    type: ActionTypes.SAVE_MANUAL_CHANGE_SUCCESS,
    payload,
    dateChanged
});

const saveManualChangeFailure = (error) => ({
    type: ActionTypes.SAVE_MANUAL_CHANGE_FAILURE,
    error
});

/**
 * save status
 * @param {object} payload status object
 * @return {{type: string, payload: *}}
 */
function saveStatusAction(payload) {
    return {type: ActionTypes.SAVE_STATUS, payload};
}

const publishPlanRequestAction = () => ({
    type: ActionTypes.PUBLISH_REQUEST
});

const publishPlanSuccessAction = () => ({
    type: ActionTypes.PUBLISH_SUCCESS
});

const publishPlanFailureAction = (error) => ({
    type: ActionTypes.PUBLISH_FAILURE,
    error
});

/**
 * publish new plan
 * @param {string} sessionId
 * @param {string} email
 * @param {string} method
 * @return {function}
 */
function publishPlanAction(sessionId, email) {
    return function (dispatch) {
        dispatch(publishPlanRequestAction());

        publishPlan(sessionId, email)
            .then(() => {
                dispatch(publishPlanSuccessAction());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "publish error"}));
                } else {
                    dispatch(publishPlanFailureAction(error));
                }
            });
    };
}

const requestScheduleRequestAction = () => ({
    type: ActionTypes.NEW_SCHEDULE_REQUEST
});

const requestScheduleSuccessAction = (payload) => ({
    type: ActionTypes.NEW_SCHEDULE_SUCCESS,
    payload
});

const requestScheduleFailureAction = (error) => ({
    type: ActionTypes.NEW_SCHEDULE_FAILURE,
    error
});
/**
 * request new ki schedule
 * @param {String} organizationId
 * @return {AnyAction}
 */
function requestScheduleAction(organizationId) {
    return function (dispatch) {
        dispatch(requestScheduleRequestAction());

        requestSchedule(organizationId)
            .then(({data}) => {
                dispatch(requestScheduleSuccessAction(data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "new schedule request error"}));
                } else {
                    dispatch(requestScheduleFailureAction(error));
                }
            });
    };
}

/**
 * save core values
 * @param {object} payload
 * @return {{type: string, payload: *}}
 */
function saveCoreValues(payload) {
    return {type: ActionTypes.SAVE_CORE_VALUES, payload};
}
/**
 * save left offset
 * @param {object} payload
 * @return {{type: string, payload: *}}
 */
function saveDragStartPosition(payload) {
    return {type: ActionTypes.SAVE_DRAG_START_POSITION, payload};
}

const completeManualChangesRequestAction = () => ({
    type: ActionTypes.COMPLETE_REQUEST
});

const completeManualChangesSuccessAction = () => ({
    type: ActionTypes.COMPLETE_SUCCESS
});

const completeManualChangesFailureAction = (error) => ({
    type: ActionTypes.COMPLETE_FAILURE,
    error
});

/**
 * complete manual changes
 * @param {object} payload {userEmail, sessionId}
 * @param {Boolean} updateSchedule
 * @return {function}
 */
function completeManualChangesAction(payload, updateSchedule) {
    return function (dispatch, getState) {
        dispatch(completeManualChangesRequestAction());
        completeManualChangesAPI(payload)
            .then(() => {
                const organizationId = selectCurrentOrganizationId(getState());
                const selectedDate = selectSelectedDate(getState());
                dispatch(loadOpManagementScheduleAction(organizationId, format(selectedDate, luxonToken.SYSTEM_DATE.de)));
                dispatch(completeManualChangesSuccessAction());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "complete manual changes error"}));
                } else {
                    dispatch(completeManualChangesFailureAction(error));
                }
            });
    };
}

const discardManualChangesRequestAction = () => ({
    type: ActionTypes.DISCARD_REQUEST
});

const discardManualChangesSuccessAction = () => ({
    type: ActionTypes.DISCARD_SUCCESS
});

const discardManualChangesFailureAction = (error) => ({
    type: ActionTypes.DISCARD_FAILURE,
    error
});

/**
 * discard manual changes
 * @param {object} payload {userEmail, sessionId}
 * @return {function}
 */
function discardManualChangesAction(payload) {
    return function (dispatch) {
        dispatch(discardManualChangesRequestAction());

        discardManualChangesAPI(payload)
            .then(() => {
                dispatch(discardManualChangesSuccessAction());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "discard manual changes error"}));
                } else {
                    dispatch(discardManualChangesFailureAction(error));
                }
            });
    };
}

const undoManualChangesRequestAction = () => ({
    type: ActionTypes.UNDO_REQUEST
});

const undoManualChangesSuccessAction = (payload) => ({
    type: ActionTypes.UNDO_SUCCESS,
    payload
});

const undoManualChangesFailureAction = (error) => ({
    type: ActionTypes.UNDO_SUCCESS,
    error
});

/**
 * undo manual changes
 * @param {object} payload {appointmentId, sessionId}
 * @return {any}
 */
function undoManualChangesAction(payload) {
    return function (dispatch, getState) {
        dispatch(undoManualChangesRequestAction());

        undoManualChangesAPI(payload)
            .then(() => {
                const organizationId = selectCurrentOrganizationId(getState());
                const selectedDate = selectSelectedDate(getState());
                fetchOpenScheduleOnSelectedDate(organizationId, format(selectedDate, luxonToken.SYSTEM_DATE.de))
                    .then(({data}) => {
                        dispatch(undoManualChangesSuccessAction(data.data));
                    })
                    .catch((error) => {
                        dispatch(undoManualChangesFailureAction(error));
                    });
                fetchStatus(organizationId)
                    .then(({data}) => {
                        dispatch(saveStatusAction(data?.data?.state));
                    })
                    .catch((error) => {
                        dispatch(undoManualChangesFailureAction(error));
                    });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "undo manual changes error"}));
                } else {
                    dispatch(undoManualChangesFailureAction(error));
                }
            });
    };
}

const getCriticalChangesSuccessAction = (payload) => ({
    type: ActionTypes.GET_CRITICAL_CHANGES,
    payload
});
const clearCriticalChangesAction = () => ({
    type: ActionTypes.CLEAR_CRITICAL_CHANGES
});
/**
 * get backlogs for given ids
 * @param {array} ids
 * @return {function}
 */
function getCriticalChangesAction(ids) {
    return function (dispatch, getState) {
        const organizationId = selectCurrentOrganizationId(getState());
        getBacklogs(ids, organizationId)
            .then(({data}) => {
                dispatch(getCriticalChangesSuccessAction(data.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "get critical changes error"}));
                }
            });
    };
}

/**
 * save hovered op
 * @param {string} [payload] opId
 * @return {{type: string, payload: *}}
 */
function changeHoveredOp(payload) {
    return {type: ActionTypes.CHANGE_HOVEREDOP, payload};
}

/**
 * save selected op
 * @param {string} [opId]
 * @return {{type: string, payload: *}}
 */
function changeSelectedOp(opId) {
    return {type: ActionTypes.CHANGE_SELECTEDOP, opId};
}

/**
 * save edit op
 * @param {object} payload
 * @param {string} [payload.id]
 * @param {string} [payload.opRoom]
 * @param {string} [payload.opStart]
 * @return {{type: string, payload: *}}
 */
function changeHighlightedOp(payload) {
    return {type: ActionTypes.CHANGE_HIGHLIGHTEDOP, payload};
}

const getUsernamesSuccessAction = (payload) => ({
    type: ActionTypes.GET_USERNAMES,
    payload
});
/**
 * get usernames of manual changes
 * @return {function}
 */
function getUsernamesAction() {
    return function (dispatch, getState) {
        const openManualChanges = selectManualChanges(getState()) || [];
        const completedManualChanges = selectSavedManualChanges(getState()) || [];
        const emails = openManualChanges.concat(completedManualChanges).map((change) => change.userEmail);
        const status = selectStatus(getState());
        if (status.session?.editedBy) {
            emails.push(status.session.editedBy);
        }
        if (status.lastPublishedSession?.publishedBy) {
            emails.push(status.lastPublishedSession.publishedBy);
        }

        if (emails.length) {
            getUsernamesAPI(uniq(emails))
                .then(({data}) => {
                    dispatch(getUsernamesSuccessAction(data.data));
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        dispatch(authUserFailureAction({error: true, message: "get usernames error"}));
                    }
                });
        } else {
            dispatch(getUsernamesSuccessAction({}));
        }
    };
}

/**
 * set fixed time
 * @param {object} payload Boolean
 * @return {{type: string, payload: *}}
 */
function setFixedTime(payload) {
    return {type: ActionTypes.SET_FIXED_TIME, payload};
}

const loadAllOpenScheduleRequestAction = () => ({
    type: ActionTypes.LOAD_ALL_OPEN_SCHEDULE_REQUEST
});

const loadAllOpenScheduleSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_ALL_OPEN_SCHEDULE_SUCCESS,
    payload
});

const loadAllOpenScheduleFailureAction = (error) => ({
    type: ActionTypes.LOAD_ALL_OPEN_SCHEDULE_FAILURE,
    error
});
/**
 * load all open ki plan for search
 * @param {String} organizationId
 * @return {any}
 */
function loadAllOpenScheduleAction(organizationId) {
    return function (dispatch) {
        dispatch(loadAllOpenScheduleRequestAction());

        fetchAllOpenSchedule(organizationId)
            .then(({data}) => {
                dispatch(loadAllOpenScheduleSuccessAction(data.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch search error"}));
                } else {
                    dispatch(loadAllOpenScheduleFailureAction(error));
                }
            });
    };
}
/**
 * set scroll to
 * @param {object} payload Boolean
 * @return {{type: string, payload: *}}
 */
function setScrollTo(payload) {
    return {type: ActionTypes.SET_SCROLL_TO, payload};
}

const loadStateRequestAction = () => ({
    type: ActionTypes.LOAD_STATE_REQUEST
});

const loadStateSuccessAction = (payload) => ({
    type: ActionTypes.LOAD_STATE_SUCCESS,
    payload
});

const loadStateFailureAction = (error) => ({
    type: ActionTypes.LOAD_STATE_FAILURE,
    error
});

/**
 * load state
 * @return {AnyAction}
 */
function loadStateAction() {
    return function (dispatch, getState) {
        const organizationId = selectCurrentOrganizationId(getState());
        dispatch(loadStateRequestAction());

        fetchStatus(organizationId)
            .then(({data}) => {
                dispatch(loadStateSuccessAction(data?.data?.state));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "auth error in load state"}));
                } else {
                    dispatch(loadStateFailureAction(error));
                }
            });
    };
}

const simulatorAddEmergencyRequestAction = () => ({
    type: ActionTypes.TRIGGER_EMERGENCY_REQUEST
});

const simulatorAddEmergencySuccessAction = (payload) => ({
    type: ActionTypes.TRIGGER_EMERGENCY_SUCCESS,
    payload
});

const simulatorAddEmergencyFailureAction = (error) => ({
    type: ActionTypes.TRIGGER_EMERGENCY_FAILURE,
    error
});

/**
 * trigger emergency
 * @return {any}
 */
function simulatorAddEmergencyAction() {
    return function (dispatch, getState) {
        dispatch(simulatorAddEmergencyRequestAction());

        simulatorInsertEmergenciesAPI()
            .then(({data: insert}) => {
                // success action
                dispatch(simulatorAddEmergencySuccessAction({insert}));
                const organizationId = selectCurrentOrganizationId(getState());
                const selectedDate = selectSelectedDate(getState());
                dispatch(loadOpManagementScheduleAction(organizationId, format(selectedDate, luxonToken.SYSTEM_DATE.de)));
            })
            .catch((error) => {
                dispatch(simulatorAddEmergencyFailureAction(error));
            });
    };
}
/**
 * save visible disciplines
 * @param {String[]} visibleDisciplines
 * @return {{type: string, visibleDisciplines: string[]}}
 */
function saveVisibleDisciplines(visibleDisciplines) {
    return {type: ActionTypes.SAVE_VISIBLE_DISCIPLINES, visibleDisciplines};
}

/**
 * set hovered op
 *
 * @param {string} opId
 * @return {{type: string, opId: string}}
 */
function setOverlapHoveredOp(opId) {
    return {type: ActionTypes.SET_OVERLAP_HOVERED_OP, opId};
}

/**
 * set clicked op
 *
 * @param {string} opId
 * @return {{type: string, opId: string}}
 */
function setOverlapClickedOp(opId) {
    return {type: ActionTypes.SET_OVERLAP_CLICKED_OP, opId};
}

export {
    changeDate,
    loadOpManagementScheduleAction,
    loadDetailOpAction,
    savePosition,
    saveOpRooms,
    saveManualChangeBackend,
    publishPlanAction,
    requestScheduleAction,
    saveCoreValues,
    saveDragStartPosition,
    loadSearchScheduleAction,
    fetchAllPatientNamesAction,
    completeManualChangesAction,
    discardManualChangesAction,
    undoManualChangesAction,
    getCriticalChangesAction,
    changeHoveredOp,
    changeSelectedOp,
    changeHighlightedOp,
    getUsernamesAction,
    setFixedTime,
    loadAllOpenScheduleAction,
    setScrollTo,
    loadStateAction,
    simulatorAddEmergencyAction,
    saveVisibleDisciplines,
    setOverlapHoveredOp,
    setOverlapClickedOp
};
